body {
  position: relative;
  min-height: 100vh;
  font-family: 'Calibri', sans-serif !important;
}

.App {
  text-align: center;
  padding-bottom: 4.5rem;
}

.nav {
  background-color: #24252a;
  color: white;
  display: flex;
  gap: 1rem;
  padding: 8px;
  align-items: center;
}

.site-title img {
  width: 35px;
  margin-left: 5px;
}

.menu {
  display: flex;
  justify-content: center;
  position: relative;
  top: 4px;
}

.menu li {
  list-style-type: none;
}

.menu li a{
  padding: 8px 15px;
  text-decoration: none;
  color: #000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #0088a9;
}

.menu li a.active{
  background-color: #0088a9;
  color: #fff;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.footer-container {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  padding:7px;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .footer-container {
    font-weight: 400;
    font-size: 14px;
  }
}

.form-result {
  width: 500px;
  margin: auto;
  padding: 30px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  background-color: #00000005;
}

.login-button {
  position: absolute;
  bottom: 0;
  z-index: 999;
}

/*table*/

.header-column {
  text-transform: uppercase;
  font-size: 14px;
}

.column-points {
  background-color: #7ccc19 !important;
  width: 110px;
  font-weight: 700;
}

.column-win {
  color: green !important;
  font-weight: 700;
}

.column-loss {
  color: red !important;
  font-weight: 700;
}

.column-value-player {
  width: 60px;
  background-color: #d6d6d6 !important;
}

.phone-number {
  text-decoration: none;
  color: #153d45;
  font-weight: 500;
}

.phone-number:hover {
  color: #0088a9;
}

.empty-table {
  padding: 20px;
}

@media (max-width: 768px){
  .container-table {
    font-size: 13px;
  }

  thead.header-column {
    font-size: 12px;
  }

  .column-points {
    width: 70px;
  }

  .table>:not(caption)>*>* {
    padding: 0.4rem 0.4rem !important;
  }

  td, th {
    line-height: 16px;
    align-content: center;
  }

}
